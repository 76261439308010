<template>
  <div class="rubric-page">
    <app-layout>
      <template slot="left-column">
        <app-left-column />
      </template>

      <template slot="central-column">
        <app-central-column />
      </template>

      <template slot="right-column">
        <app-right-column />
      </template>
    </app-layout>
  </div>
</template>

<script>
import { pageAnalyticsMixinTs } from '../../../shared';
import { WindowScrollMixin } from '../../../shared';

import AppLayout from '../../../layout/containers/app-layout';
import AppLeftColumn from '../../../layout/containers/app-left-column';
import AppCentralColumn from '../../../layout/containers/app-central-column';
import AppRightColumn from '../../../layout/containers/app-right-column';

export default {
  name: 'RubricPage',
  components: {
    AppLayout,
    AppLeftColumn,
    AppCentralColumn,
    AppRightColumn
  },
  mixins: [WindowScrollMixin, pageAnalyticsMixinTs],
  beforeRouteUpdate(to, from, next) {
    this.updatePage(to.params);
    next();
  },
};
</script>

<style lang="scss" scoped>
.rubric-page {
  display: block;
}
</style>
