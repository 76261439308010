import { isSameRegion, Regions } from '@smh/projects/regions';
import { component } from 'vue-tsx-support';
import { useStore } from 'vuex-simple';

import { PagesType, PAGE_ANALYTICS_NAME } from '@jtnews/shared/data';
import type { MetatagsBlock } from '@jtnews/shared/newsapi/base';
import { RootModule } from '@jtnews/store';

import { CommonModule } from '../../../core/store/common';

const BOUNCE_TIMEOUT = 15000;

type GAParams = {
  category?: string;
  action?: string;
  label?: string;
  value?: number;
};

type ReachGoal = {
  name: string;
  params?: ValueParams;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
interface GoalValueParams {
  [prop: string]: Record<string, GoalValueParams> | string;
}

type ValueParams = string | number | string[] | Record<string, string | GoalValueParams>;

type RecordFormats = [{ id: number; name: string; url: string }];

type RecordAnalyticsData = {
  recordId: number;
  authors: string[];
  categories: string[];
  type: '' | 'Новость' | 'Статья' | 'Спецпроект';
  publicationDate: string;
  formats: RecordFormats;
  header: string;
};

type PageAnalyticsData = {
  href: string;
  pathname: string;
  search: string;
  canonicalUrl: string;
  title: string;
  language: string;
  type: string;
  recordData?: RecordAnalyticsData;
};

type RecordAuthor = { name: string };
type RecordRubric = { name: string };

type RecordBlock = {
  data?: {
    id: number;
    authors?: RecordAuthor[];
    isCommercial?: boolean;
    rubrics?: RecordRubric[];
    formats?: RecordFormats;
    analyticsType: '' | 'Новость' | 'Статья' | 'Спецпроект';
    rfc822PublishAt: string;
    header: string;
  };
};

type ComponentData = {
  store: RootModule;
};

export const pageAnalyticsMixinTs = component({
  data(): ComponentData {
    return {
      store: useStore<RootModule>(this.$store)
    };
  },
  computed: {
    regionId(): Regions {
      return this.store.regionId;
    },
    pageType(): string {
      return this.store.pageType;
    },
    metatags(): MetatagsBlock {
      return this.store.metatags;
    },
    commonModule(): CommonModule | undefined {
      return this.store.commonModule;
    },
    pageReachGoals(): ReachGoal[] {
      return this.commonModule?.commonReachGoal?.data || [];
    },
    pageGAEvents(): GAParams[] {
      return this.commonModule?.commonGAEvents?.data || [];
    },
    hasPerformanceMetricsCollection(): boolean {
      return isSameRegion(this.regionId, Regions.Krasnoyarsk);
    }
  },
  beforeMount() {
    if (this.hasPerformanceMetricsCollection) {
      this.commonModule?.startCollectPerformanceMetrics();
    }
  },
  mounted() {
    this.sendPageAnalyticsData();

    if (this.hasPerformanceMetricsCollection) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      window.addEventListener('beforeunload', this.sendPageMetricsData);
    }
  },
  beforeDestroy() {
    if (this.hasPerformanceMetricsCollection) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      window.removeEventListener('beforeunload', this.sendPageMetricsData);
    }
  },
  methods: {
    sendPageAnalyticsData() {
      const analyticsData = this.getPageAnalyticsData();
      if (this.pageType === PagesType.Record || this.pageType === PagesType.Longread) {
        analyticsData.recordData = this.getRecordAnalyticsData();
      }

      // сначала отправляем общую аналитику
      this.store.analyticsModule.sendPageAnalyticsData(analyticsData);

      // потом отправляет глобальные рич-голы для страницы
      if (this.pageReachGoals && this.pageReachGoals.length > 0) {
        this.pageReachGoals.forEach(reachGoal => {
          const { name, params } = reachGoal;

          this.store.analyticsModule.sendReachGoal({ name, params });
        });
      }

      setTimeout(() => {
        this.store.analyticsModule.sendReachGoal({ name: 'notBounce' });
      }, BOUNCE_TIMEOUT);

      this.pageAnalyticsDataSent();
    },
    // метод, который вызывается после каждой отправки аналитики,
    // чтобы в самом компоненте можно было сделать какие-нибудь действия
    // после отправки аналитики
    // eslint-disable-next-line
    pageAnalyticsDataSent() {},
    getPageAnalyticsData(): PageAnalyticsData {
      const {
        location: { href, pathname, search }
      } = window;

      return {
        href,
        pathname,
        search,
        canonicalUrl: `${this.metatags.canonical}`,
        title: this.metatags.title,
        language: 'ru',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        type: PAGE_ANALYTICS_NAME[this.pageType]
      };
    },
    getRecordAnalyticsData(): RecordAnalyticsData | undefined {
      const recordBlock = this.store.layoutModule.record as RecordBlock;

      if (recordBlock?.data === undefined) {
        return;
      }

      const recordData = recordBlock.data;
      let authors: string[] = [];

      if (recordData.authors !== undefined && recordData.authors.length > 0) {
        authors = recordData.authors.map(a => a.name);
      }

      let categories: string[] = [];

      if (recordData.isCommercial) {
        categories = ['РЕКЛАМА'];
      } else {
        const { rubrics } = recordData;
        if (rubrics !== undefined && rubrics.length > 0) {
          categories = rubrics.map(r => r.name);
        }
      }

      let formats = [] as unknown as RecordFormats;

      if (recordData.formats !== undefined && recordData.formats.length > 0) {
        formats = [...recordData.formats];
      }

      const type = recordData.analyticsType;
      const publicationDate = recordData.rfc822PublishAt;
      const recordId = recordData.id;
      const { header } = recordData;

      return {
        recordId,
        authors,
        categories,
        type,
        publicationDate,
        formats,
        header
      };
    },
    sendPageMetricsData() {
      this.commonModule?.sendPagePerformanceMetrics();
    }
  }
});
